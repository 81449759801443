import React from "react";
import theme from "theme";
import { Theme, Span, Text, Image, LinkBox, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"4"} />
		<Helmet>
			<title>
				Begin Your Transformation
			</title>
			<meta name={"description"} content={"Don't wait to start living better. Download The Coach now and embark on a personalized journey toward enhanced health, greater happiness, and a life of fulfillment"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Box
			min-width="100px"
			min-height="100vh"
			background="#141D19"
			display="flex"
			flex-direction="column"
			align-items="center"
			padding="0px 7% 7% 7%"
			flex="1"
		>
			<Text
				margin="0px 0px 0px 0px"
				font="normal 400 50px/80px 'Druk', Impact, Haettenschweiler, 'Franklin Gothic Bold', Charcoal, 'Helvetica Inserat', 'Bitstream Vera Sans Bold', 'Arial Black', sans-serif"
				text-align="center"
				color="#f3f4f4"
				padding="15% 0px 3% 0px"
			>
				Begin Your
				<br />
				<Span
					color="#ff6d03"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Transformation
				</Span>
			</Text>
			<Text margin="0px 0px 0px 0px" font="600 22px/1.55 --fontFamily-sans" color="#f3f4f4">
				Don't wait to start living better. Download The Coach now and embark on a
 personalized journey toward enhanced health, greater happiness, and a 
life of fulfillment
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				padding="15% 0px 3% 0px"
			>
				<LinkBox quarkly-title="AppStore" href="https://apple.co/32Ozkse?utm_source=maleprohealth-test&utm_medium=button&utm_campaign=web" margin="0px 0px 0px 0px" padding="0px 0px 10% 0px">
					<Image
						src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge-1_optimized.png?v=2023-06-13T07:48:07.383Z"
						display="block"
						alt="App Store button"
						height="90px"
						srcSet="https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge-1_optimized.png?v=2023-06-13T07%3A48%3A07.383Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge-1_optimized.png?v=2023-06-13T07%3A48%3A07.383Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge-1_optimized.png?v=2023-06-13T07%3A48%3A07.383Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge-1_optimized.png?v=2023-06-13T07%3A48%3A07.383Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge-1_optimized.png?v=2023-06-13T07%3A48%3A07.383Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge-1_optimized.png?v=2023-06-13T07%3A48%3A07.383Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge-1_optimized.png?v=2023-06-13T07%3A48%3A07.383Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</LinkBox>
				<LinkBox quarkly-title="AppStore" href="https://play.google.com/store/apps/details?id=com.vamapps.thecoach&utm_source=maleprohealth-test&utm_medium=button&utm_campaign=web">
					<Image
						src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge_optimized.png?v=2023-06-13T07:48:19.068Z"
						display="block"
						alt="App Store button"
						height="90px"
						srcSet="https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge_optimized.png?v=2023-06-13T07%3A48%3A19.068Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge_optimized.png?v=2023-06-13T07%3A48%3A19.068Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge_optimized.png?v=2023-06-13T07%3A48%3A19.068Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge_optimized.png?v=2023-06-13T07%3A48%3A19.068Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge_optimized.png?v=2023-06-13T07%3A48%3A19.068Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge_optimized.png?v=2023-06-13T07%3A48%3A19.068Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/mobile-app-store-badge_optimized.png?v=2023-06-13T07%3A48%3A19.068Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</LinkBox>
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65093a108bd7e600185e78d9"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfHead"} rawKey={"6511ac8515cc038ca22e9a60"}>
				{"window.mainTracker = \"gtag\";\nwindow.gtagTrackerID = \"G-2WL1E4LC2C\";\nfunction gtag() {\n  dataLayer.push(arguments);\n}\n\nfunction loadGTM() {\n  (function (w, d, s, i) {\n    var f = d.getElementsByTagName(s)[0],\n      j = d.createElement(s);\n    j.async = true;\n    j.src = \"https://www.googletagmanager.com/gtag/js?id=\" + i;\n    j.onload = function() {\n      gtag(\"js\", new Date());\n      gtag(\"config\", i, {});\n    }\n    f.parentNode.insertBefore(j, f);\n  })(window, document, \"script\", window.gtagTrackerID);\n}\n\nwindow.onload = function() {\n  if (window.requestIdleCallback) {\n    window.requestIdleCallback(loadGTM, { timeout: 150000 });\n  } else {\n    setTimeout(loadGTM, 2000);\n  }\n}\n"}
			</script>
		</RawHtml>
	</Theme>;
});